import React from 'react'
import { Link } from 'gatsby'

import netlify from '../../content/thumbnails/netlify.png'
import gatsby from '../assets/gatsby.png'
import react from '../assets/nav-react.png'

const links = [
  { url: 'https://bangdenspace.substack.com/subscribe', label: 'Newsletter' },
  { url: 'https://bangden.space/privacy-policy', label: 'Privacy Policy' },
  { url: 'https://bangden.space/terms-and-conditions', label: 'Terms & Conditions'},
  { url: 'https://bangden.space/disclaimer', label: 'Disclaimer'},
]
const internalLinks = [{ url: '/rss.xml', label: 'RSS' }]
const madeWithLinks = [
  { url: 'https://www.gatsbyjs.org/', label: 'Gatsby', icon: gatsby },
  { url: 'https://reactjs.org', label: 'React', icon: react },
  { url: 'https://www.netlify.com', label: 'Netlify', icon: netlify },
]

export const Footer = () => {
  return (
    <footer className="footer">
      <section>
        <nav>
          <span className="desktop-only">Made with ❤️</span>
          {links.map((link) => (
            <a
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              key={link.url}
            >
              {link.label}
            </a>
          ))}
          {internalLinks.map((link) => (
            <Link to={link.url} key={link.url}>
              {link.label}
            </Link>
          ))}
        </nav>
        <nav>
          <span>Build with:</span>
          {madeWithLinks.map((link) => (
            <a
              href={link.url}
              title={link.label}
              target="_blank"
              rel="noopener noreferrer"
              key={link.url}
            >
              <span>{link.label}</span>
              <img src={link.icon} alt={link.label} />
            </a>
          ))}
        </nav>
      </section>
    </footer>
  )
}
